import { useCallback, useEffect, useRef, useState } from "react";
import { useSearchParams } from "react-router-dom";
import DataGrid, {
  Column,
  CustomRule,
  Editing,
  Format,
  Selection,
  Summary,
  TotalItem,
} from "devextreme-react/data-grid";
import differenceInDays from "date-fns/differenceInDays";
import parseJSON from "date-fns/parseJSON";
import Cards from 'react-credit-cards-2';
import 'react-credit-cards-2/dist/es/styles-compiled.css';
import Spinner from "../components/common/Spinner";
import { fetchGatewayReceiptPdf, fetchOrder, submitPayment } from "../context/order/orderActions";
import {
  CustButton,
  CustCol,
  CustDropDown,
  CustModal,
  CustRow,
  CustSwitch,
  CustText,
  ModalBody,
  ModalFooter,
  SectionTitle
} from "../components/common";
import { formatCurrency, roundDecimal } from "../helpers";
import logo from "../assets/img/logo.png";
import spinner from "../assets/img/page-loader.gif";
import { ReactComponent as IconCheck } from "../assets/img/checkmark.svg"
import { ReactComponent as IconAlert } from "../assets/img/flag.svg";
import { TERMS } from "../constants";
import { Popover } from "devextreme-react";

const animationConfig = {
  show: {
    type: 'pop',
    from: { scale: 0 },
    to: { scale: 1 },
  },
  hide: {
    type: 'fade',
    from: 1,
    to: 0,
  },
};

function Pay() {
  const [searchParams] = useSearchParams();
  const idParm = searchParams.get('id');
  const allOrdersParm = searchParams.get('all');
  const fromArtworkPrepay = searchParams.get('artwork');
  const timelyAchDiscountPercent = 1.5;

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    // Clean up event listener when component unmounts
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const [localState, setLocalState] = useState({
    isLoading: true,
    order: {},
    gridData: [],
    config: {},
    selectedBalanceTotal: 0.0,
    selectedDiscountTotal: 0.0,
    selectedKeys: [],
    selectedFirstRow: false,
    paymentSuccessful: false,
    transactionIds: [],
    ipInfo: {},
    notFound: false,
    finalCancel: false,
    choseAch: true,
  });

  const [paymentInfo, setPaymentInfo] = useState({
    showModal: false,
    achRouting: undefined,
    achAccount: undefined,
    accountType: "checking",
    checkType: "business",
    ccNumber: "",
    ccExpiration: "",
    ccName: "",
    ccCvC: "",
    ccFocused: "",
    useVault: false,
    storeToVault: false,
  });

  const achCalcForOrder = useCallback((isAch, order) => {
    const { invoicedDate, totalReceipts, total } = order;
    const termsLookup = (TERMS.find((x) => x.id === order.terms)) || TERMS.find((x) => x.id === "Net 30");

    const applyAchDiscount = isAch && !termsLookup.discountPercent &&
          (!invoicedDate || (!!invoicedDate && differenceInDays(new Date(), parseJSON(invoicedDate)) <= (termsLookup.daysDue || 30)));
    const requiresPrepay = !!fromArtworkPrepay;

    const amountToPayBeforeDiscount = requiresPrepay
      ? roundDecimal(order.subtotal * (termsLookup.upFrontPercent * 0.01) - totalReceipts)
      : roundDecimal(total - totalReceipts);

    const discountAmount = applyAchDiscount
      ? roundDecimal((amountToPayBeforeDiscount - (order.freightPrice || 0)) * timelyAchDiscountPercent * 0.01, 2)
      : termsLookup.discountPercent ? order.discountAmount : 0;

    const amountToPay = roundDecimal(amountToPayBeforeDiscount - discountAmount);

    return {
      discountAmount,
      amountToPay,
    };
  }, [fromArtworkPrepay]);
  
  const applyPricing = (isAch) => {
    setLocalState((prev) => {
      
      let selectedBalanceTotal = 0.0, selectedDiscountTotal = 0.0;
      let gridData = prev.gridData.map((r) => {
        const calcs = achCalcForOrder(isAch, r);
        if (prev.selectedKeys.includes(r.id)) {
          selectedBalanceTotal += calcs.amountToPay;
          selectedDiscountTotal += calcs.discountAmount;
        };

        return {
          ...r,
          amountToPay: calcs.amountToPay,
          discountAmount: calcs.discountAmount,
        };
      });
      
      return {
        ...prev,
        gridData,
        selectedBalanceTotal,
        selectedDiscountTotal,
        choseAch: isAch,
      };
    });

    setPaymentInfo((prev) => { return {...prev, useVault: false, storeToVault: false}});
  };

  const handleCancelSubmit = () => {
    if (localState.isLoading) return;
    setPaymentInfo((prev) => {
      return {
        ...prev,
        showModal: false,
      };
    });
  };

  const accountTypeOptions = [
    {key: "checking", value: "checking", text: "Checking"},
    {key: "savings", value: "savings", text: "Savings"},
  ];

  const checkTypeOptions = [
    {key: "business", value: "business", text: "Business"},
    // {key: "personal", value: "personal", text: "Personal"},
  ];
  
  const isValidPaymentInfo = 
    paymentInfo.useVault ||
    (localState.choseAch && paymentInfo.achRouting?.length === 9 &&
    !!paymentInfo.achAccount && paymentInfo.achAccount?.length < 18 &&
    accountTypeOptions.find((r) => r.key === paymentInfo.accountType) &&
    checkTypeOptions.find((r) => r.key === paymentInfo.checkType)) ||
    (!localState.choseAch &&
      paymentInfo.ccNumber?.length > 12 &&
      paymentInfo.ccExpiration?.length === 4 &&
      paymentInfo.ccName?.length > 4 &&
      paymentInfo.ccCvC.length > 2
    );

  const handleFormFieldChange = (fieldName, newVal) => {
    if (localState.isLoading) return;

    if (fieldName === "ccNumber") {
      const firstChar = !!newVal ? newVal.slice(0, 1) : "";
      const maxLength = firstChar === "3" ? 15 : 16;
      if (newVal?.length > maxLength) return;
    };

    if (fieldName === "ccExpiration") {
      if (newVal?.indexOf("/") !== -1) return;
      if (newVal?.length > 4) return;
      const firstChar = !!newVal ? newVal.slice(0, 1) : "";
      const secondChar = !!newVal ? newVal.slice(1, 2) : "";
      if (!!newVal) {
        if (firstChar !== "0" && firstChar !== "1") return;
        if (firstChar === "1" && secondChar > "2") return;
      };
    };

    if (fieldName === "ccName" && newVal?.length > 30) return;

    if (fieldName === "ccCvC" && newVal?.length > 4) return;

    setPaymentInfo((prevData) => {
      return {
        ...prevData,
        [fieldName]: newVal,
      }
    });
  };

  const handleCcFieldFocus = (e) => {
    if (localState.isLoading) return;
    const {id} = e.target;

    setPaymentInfo((prev) => {
      let ccFocused = "";
      if (id === "ccNumber") ccFocused = "number";
      if (id === "ccExpiration") ccFocused = "expiry";
      if (id === "ccName") ccFocused = "name";
      if (id === "ccCvC") ccFocused = "cvc";
      return {
        ...prev,
        ccFocused,
      };
    });
  };
  
  const handleAchVerification = (e, maxLength) => {
    const newValue = e.target.value;
    if (!maxLength || newValue.length <= maxLength) {
      handleFormFieldChange(e.target.id, newValue);
    };
  };
  
  const finishSubmit = useCallback(async () => {
    if (localState.paymentSuccessful || !isValidPaymentInfo || localState.isLoading) return;

    let selectedItems = localState.gridData
      .filter((r) => localState.selectedKeys.includes(r.id))
      .map((r) => {
        return {
          id: r.id,
          amountToPay: r.amountToPay,
          customerPo: r.customerPo,
          invoicedDate: r.invoicedDate,
        };
      });

    setLocalState((prev) => {
      return {
        ...prev,
        isLoading: true,
      };
    });

    const response = await submitPayment(
      idParm,
      roundDecimal(localState.selectedBalanceTotal),
      selectedItems,
      paymentInfo,
      fromArtworkPrepay,
      localState.choseAch,
    );
   
    setLocalState((prev) => {
      return {
        ...prev,
        isLoading: false,
        transactionIds: response?.data?.transactionIds || [],
        paymentSuccessful: response?.data?.status === 0,
      };
    });

    if (response?.data?.status === 0) {
      setPaymentInfo((prev) => {
        return {
          ...prev,
          showModal: false,
        };
      });

      await fetchGatewayReceiptPdf(idParm, response?.data?.transactionIds);

    } else {
      if (response.data.status === -2) {
        alert("Your payment was processed, but there was a problem applying it to your invoices.  Apologies - please do not resubmit, and contact Accounts Receivable");
      } else alert(`Payment was not processed.  ${response?.data?.message}`);
    };
  }, [isValidPaymentInfo, fromArtworkPrepay, localState, paymentInfo, idParm]);

  const isFetched = useRef(false);

  const lookupOrder = useCallback(async (id) => {
    if (isFetched.current) return;

    const results = await fetchOrder(id, fromArtworkPrepay);
    results.order.termsLookup = (TERMS.find((r) => r.id === results?.order.terms)) || {};

    setLocalState((prev) => {
      let gridData = results.otherOpenOrders?.map((r) => {
        const calcs = achCalcForOrder(true, r);
        return {
          ...r,
          amountToPay: calcs.amountToPay,
          discountAmount: calcs.discountAmount,
          isValid: true,
        };
      });
      
      return {
        ...prev,
        isLoading: false,
        order: results?.order || {customer: {}},
        gridData: gridData || [],
        config: results?.config || {},
        selectedBalanceTotal: 0.0,
        selectedDiscountTotal: 0.0,
        choseAch: true,
        selectedKeys: [],
        ipAddress: results?.ipAddress,
        notFound: !results?.order?.id,
      };
    });

    if (!!results.order.customer?.achVaultId && !!results.order?.customer?.lastFourBankAcct) {
      setPaymentInfo((prev) => {
        return {
          ...prev,
          useVault: true,
        };
      });
    };
  }, [achCalcForOrder, fromArtworkPrepay]);

  useEffect(() => {
    if (!idParm || !!isFetched.current) return;

    lookupOrder(idParm);
    isFetched.current = true;
    
  }, [idParm, lookupOrder]);

  if (localState.notFound) {
    return (
      <h1>Order not found</h1>
    )
  };

  const {order} = localState;
  if (localState.isLoading) return <Spinner />;

  const onSelectionChanged = ({ selectedRowKeys, selectedRowsData, forceAdd }) => {
    if (localState.paymentSuccessful || localState.isLoading || (forceAdd && localState.selectedFirstRow && !allOrdersParm)) return;

    setLocalState((prevData) => {
      let addedRowKey = null, removedRowKey = null;
      if (selectedRowKeys.length > prevData.selectedKeys.length) {
        addedRowKey = (selectedRowKeys.filter(item => !prevData.selectedKeys.includes(item)))[0];
      } else {
        if (!forceAdd) removedRowKey = (prevData.selectedKeys.filter(item => !selectedRowKeys.includes(item)))[0];
      };

      if (forceAdd) addedRowKey = selectedRowKeys[0];

      const gridData = prevData.gridData.map((item) => {
        const calcs = achCalcForOrder(localState.choseAch, item);
        if (item.id === addedRowKey || (selectedRowKeys.includes(item.id) && !item.amountToPay)) {
          item.amountToPay = calcs.amountToPay;
        };
        if (item.id === removedRowKey || !selectedRowKeys.includes(item.id)) item.amountToPay = undefined;
        item.isValid = true;
        return item;
      });
      
      let selectedBalanceTotal = 0.0, selectedDiscountTotal = 0.0;
      
      selectedRowsData.forEach((r) => {
        const calcs = achCalcForOrder(localState.choseAch, r)
        selectedBalanceTotal += calcs.amountToPay || 0.0;
        selectedDiscountTotal += calcs.discountAmount || 0.0;
      });

      return {
        ...prevData,
        selectedKeys: selectedRowKeys.map((x) => x),
        gridData,
        selectedBalanceTotal,
        selectedDiscountTotal,
        selectedFirstRow: true,
      };
    });
  };

  const validateAmount = (e) => {
    const isValid = e.value > 0 && e.value <= e.data.balance && (!fromArtworkPrepay || e.value >= order.prepayAmountNeeded);
    
    setLocalState((prev) => {
      if (isValid) {
        let selectedBalanceTotal = 0.0, selectedDiscountTotal = 0.0;
        const gridData = prev.gridData.map((item) => {
          if (item.id === e.data.id) {
            item.amountToPay = e.value;
            item.isValid = true;
          };      
          selectedBalanceTotal += item.amountToPay || 0.0;
          selectedDiscountTotal += item.discountAmount || 0.0;
          return {...item};
        });

        return {
          ...prev,
          gridData,
          selectedBalanceTotal,
          selectedDiscountTotal,
        };

      } else {
        const gridData = prev.gridData.map((item) => {
          if (item.id === e.data.id) item.isValid = false;
          return item;
        });
        return {
          ...prev,
          gridData,
        };
      };
    });
 
    return isValid;
  };

  const invalidAmountToPay = orders => {
    let invalid = false;
    orders.forEach((r) => {
      if (!r.isValid) invalid = true;
    });
    return invalid;
  }

  const handleSelectionsDone = () => {
    if (localState.paymentSuccessful || localState.isLoading) return;
    
   setPaymentInfo((prev) => {
    return {
      ...prev,
      showModal: true,
    };
   });
  };

  const handleCancel = () => {
    setLocalState((prev) => {
      return {
        ...prev,
        finalCancel: true,
      };
    });
  };

  const initiallySelectFirstRow = (e) => {
    if (localState.selectedFirstRow || !localState.gridData.length) return;

    const { gridData } = localState;

    if (!!allOrdersParm) {
      e.component.selectAll();
      return;
    };

    // the guid may be for an order that was already paid.
    // if there are multiple orders in the grid, don't just use the first one - find the order # that correlates to the guid
    let index = gridData.findIndex((r) => r.id === localState.order.id);
    if (index === -1) index = 0;

    e.component.selectRowsByIndexes(index);
    onSelectionChanged({
      selectedRowKeys: [gridData[index].id],
      selectedRowsData: [{...gridData[index], amountToPay: fromArtworkPrepay ? gridData[index].prepayAmountNeeded : gridData[index].balance}],
      forceAdd: true,
    });
  };

  const cancelEditIfNotSelected = (e) => {
    if (!localState.selectedKeys.includes(e.data.id)) e.cancel = true;
  };

  const onEditCanceling = (e) => {
    if (!e.changes.length) return;

    const key = e.changes[0].key;
    if (!key) return;

    setLocalState((prev) => {
      const gridData = prev.gridData.map((x) => {
        if (x.id === key) {
          x.amountToPay = fromArtworkPrepay ? x.prepayAmountNeeded : x.balance;
          x.isValid = true;
        };
        return {...x};
      });

      return {
        ...prev,
        gridData,
      };
    });
  };

  const canUseVault = (!!localState.choseAch && !!order?.customer?.achVaultId && !!order?.customer?.lastFourBankAcct) || 
    (!localState.choseAch && !!order?.customer?.ccVaultId && !!order.customer?.lastFourCC);
  const achCheck = localState.choseAch ? <IconCheck style={{fill: "#fff", position: "absolute", top: "3px", right: 12}} /> : <span>&nbsp;</span>
  const ccCheck = localState.choseAch ? <span>&nbsp;</span> : <IconCheck style={{fill: "#fff", position: "absolute", top: "3px", right: 12}} />
  return (
    <>
      <div style={{display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column"}}>
        <h1>{localState.config.clientName}</h1>
        <img src={logo} className="pr-2" alt="logo" style={{ height: "10rem", marginTop: "-15px" }} />
        <label style={{marginTop: "-5px"}}>CALIFORNIA  -  MONTANA  -  OHIO  -  VIENNA</label>
      </div>

      <div className="mt-5">
        <CustRow className="mt-2">
          <CustCol cols={12}>
            <CustRow>
              <CustCol cols={12}>
                <h2>{order.customer?.businessName} invoice payment</h2>
              </CustCol>
            </CustRow>
            <CustRow>
              <CustCol cols={12}>
              <label style={{lineHeight: 1.2}} className="comp-input-label">Your Rep is {order.staff?.name}, who may be contacted at {order.staff?.cellPhone}{order.staff?.email ? `, and emailed at ${order.staff.email}` : ""}</label>
              </CustCol>
            </CustRow>

            <CustRow className="mt-2">
              <CustCol cols={10}><label className="comp-input-label">Terms are {order.terms}</label></CustCol>
            </CustRow>
          </CustCol>
        </CustRow>

        {!localState.paymentSuccessful && localState.gridData.length && (
          <div className="ml-10  pl-3 my-5">
            
            <p className="comp-input-label">
              {!localState.order?.termsLookup?.discountPercent
                ? <span>Don't miss this discount opportunity - if you choose to pay via secure ACH prior to your due date, a {timelyAchDiscountPercent}% discount will be applied to qualifying orders
                  <span className="italic">&nbsp;(excludes shipping).</span>&nbsp;</span>
                : <span>Would you like to pay via ACH?</span>
              }
            </p>
            <div className="mt-3">
              <CustButton
                id="choose-ach-1-button"
                onClick={() => applyPricing(true)}
                disabled={localState.finalCancel}
                custType="primary"
                tabIndex={10}
              >
                <span className="dx-button-text">Yes! I'll pay with ACH &nbsp;&nbsp;&nbsp;&nbsp;
                {localState.choseAch && achCheck}&nbsp;&nbsp;&nbsp;&nbsp;</span>
              </CustButton>

              <CustButton
                id="choose-cc-1-button"
                onClick={() => applyPricing(false)}
                disabled={localState.finalCancel}
                tabIndex={20}
                >
                <span className="dx-button-text">No thanks - I want to use a credit card &nbsp;&nbsp;&nbsp;&nbsp;
                {!localState.choseAch && ccCheck}&nbsp;&nbsp;&nbsp;&nbsp;</span>
              </CustButton>
            </div>
          </div>
        )}
      </div>
        
      <div className="ml-10">
        {localState.paymentSuccessful && (
          <div className="mb-5 ml-10">
            <SectionTitle>Thank you {order.customer?.businessName}! </SectionTitle> 
            <p className="comp-input-label" style={{fontSize: 16, lineHeight: 1.4}}>
              Transaction #{localState.transactionIds.length > 1 ? "s" : ""} {localState.transactionIds.join(", ")}<br />
              Your payment of {formatCurrency(localState.selectedBalanceTotal)} has been submitted.<br />
              {fromArtworkPrepay ? `Order ${order.id} has been released for printing, and we'll send you an update of the estimated ship date.` : ""}
            </p>
          </div>
        )}

      {!localState.notFound && !localState.gridData.length && (
        <SectionTitle>No open orders found</SectionTitle>
      )}

        {(!!localState.gridData.length && !localState.paymentSuccessful) && (
          <>
            {localState.gridData.length > 1 && !allOrdersParm && (
              <SectionTitle>Save Time! Pay for multiple orders at once by clicking the associated checkboxes</SectionTitle>
            )}
            {localState.gridData.length > 1 && !!allOrdersParm && (
              <SectionTitle>Select which orders to pay by checking / unchecking the box to the left</SectionTitle>
            )}

            <div className="my-3 ml-10">
              <DataGrid
                id="other-invoices-grid"
                dataSource={localState.gridData}
                keyExpr="id"
                allowColumnReordering={true}
                allowColumnResizing={true}
                columnAutoWidth={true}
                onContentReady={initiallySelectFirstRow}
                onEditingStart={cancelEditIfNotSelected}
                onEditCanceling={onEditCanceling}
                rowAlternationEnabled={true}
                showBorders={true}
                onSelectionChanged={onSelectionChanged}
                disabled={localState.paymentSuccessful || localState.isLoading || localState.finalCancel}
              >
                <Selection
                  mode="multiple"
                  selectAllMode="allPages"
                  showCheckBoxesMode="always"
                />
                <Editing
                  mode="cell"
                  allowUpdating={false}
                  allowAdding={false}
                  allowDeleting={false}
                />
                <Column
                  caption="Purchase Order"
                  dataField="customerPo"
                  width="12.4rem"
                  allowEditing={false}
                />

                <Column
                  caption="Invoice #"
                  dataField="id"
                  width="12rem"
                  cellRender={(r) => `${localState.config?.orderPrefix}${r.data.id}`}
                  allowEditing={false}
                />

                <Column
                  caption="Invoice Date"
                  dataField="invoicedDate"
                  width="12.4rem"
                  dataType="date"
                  allowEditing={false}
                />

                <Column caption="Invoice Amt" dataField="total" width="11rem" allowEditing={false}>
                  <Format type="currency" precision={2} />
                </Column>

                <Column
                  caption="Payments and Adj"
                  dataField="totalReceipts"
                  width="15rem"
                  allowEditing={false}
                >
                  <Format type="currency" precision={2} />
                </Column>

                <Column
                  caption="Discount"
                  dataField="discountAmount"
                  width="15rem"
                  allowEditing={false}
                  cellRender={(r) => formatCurrency(r.data.discountAmount)}
                >
                  <Format type="currency" precision={2} />
                </Column>

                <Column caption="Amount Due" dataField="balance" width="12rem" allowEditing={false}>
                  <Format type="currency" precision={2} />
                </Column>

                <Column
                  caption="Amount to Pay today"
                  dataField="amountToPay"
                  width="12rem"
                  allowEditing={false}
                >
                  <Format type="currency" precision={2} />
                  <CustomRule
                    ignoreEmptyValue={true}
                    message={`Value must be between 0 and the ${fromArtworkPrepay ? "amount of the upfront terms" : "Amount Due"}`}
                    validationCallback={validateAmount}
                  />
                </Column>

                <Summary>
                  <TotalItem
                    column="totalReceipts"
                    customizeText={() => localState.choseAch ? "Total Discount:" : ""}
                  />

                  <TotalItem
                    column="discountAmount"
                    summaryType="sum"
                    customizeText={() => localState.choseAch ? formatCurrency(localState.selectedDiscountTotal) : null}
                  />

                  <TotalItem
                    column="balance"
                    customizeText={() => "Selected Total:"}
                  />

                  <TotalItem
                    column="amountToPay"
                    summaryType="sum"
                    customizeText={() => formatCurrency(localState.selectedBalanceTotal)}
                  />
                </Summary>
              </DataGrid>
            </div>

            <div className="ml-10">
              <CustButton
                text={`Authorize Payment for ${formatCurrency(localState.selectedBalanceTotal)} via ${localState.choseAch ? "ACH" : "Credit Card"}...`}
                onClick={handleSelectionsDone}
                disabled={localState.paymentSuccessful || (roundDecimal(localState.selectedBalanceTotal) <= 0.0) ||
                  invalidAmountToPay(localState.gridData) || localState.isLoading || localState.finalCancel}
                tabIndex={30}
                className="mt-5"
                width={320}
              />

              <CustButton
                text="Cancel"
                onClick={handleCancel}
                disabled={localState.finalCancel}
                tabIndex={40}
                className="mt-5"
              />
            </div>
          </>
        )}

        {localState.finalCancel && !!fromArtworkPrepay && (
          <div className="mt-10">
            <label className="comp-input-label">OK, you'd like to pay at a later time. &nbsp;Please be aware that your order will be placed on hold 
              until the upfront payment requirements are met.</label><br /><br />
            <label className="comp-input-label">You may now close this window.</label>
          </div>
        )}

        {localState.finalCancel && !fromArtworkPrepay && !localState.paymentSuccessful && (
          <div className="mt-10">
            <label className="comp-input-label">You're welcome to revisit this page to pay your invoice at a later time. &nbsp;You may now close this window.</label>
          </div>
        )}

        <div className="mt-10">
          <p style={{fontSize: "80%"}}>
            {localState.ipAddress} This software was custom designed for {localState.config.clientName}. If you'd like to discuss your custom software needs, contact <a href="https://406partnership.com" target="_blank" rel="noreferrer">406 Partnership, LLC</a> 
          </p>
        </div>
      </div>

      <CustModal
        id="payment-modal"
        title={`Payment Authorization via ${localState.choseAch ? "ACH" : "credit card"}`}
        showModal={paymentInfo.showModal}
        size="small"
        hideClose={true}
        onClose={handleCancelSubmit}
        disabled={localState.isLoading}
      >
        <ModalBody>
          {canUseVault &&
            <CustRow>
              <CustCol cols={12}>
                <CustSwitch
                  id="use-vault"
                  label={`Would you like to use your ${localState.choseAch ? "bank account" : "card"} ending in 
                    ${localState.choseAch ? order.customer.lastFourBankAcct : order.customer.lastFourCC}?`}
                  checked={paymentInfo.useVault}
                  labelPosition="left"
                  onChange={(e) => handleFormFieldChange("useVault", e.target.checked)}
                />
              </CustCol>
            </CustRow>
          }

          {!localState.choseAch && !paymentInfo.useVault &&
            <div>
              <CustRow>
                <CustCol cols={4}>
                  <CustText
                    id="ccNumber"
                    label="Card Number"
                    onChange={(e) => handleFormFieldChange(e.target.id, e.target.value)}
                    onFocus={handleCcFieldFocus}
                    value={paymentInfo.ccNumber}
                    type="number"
                    disabled={localState.isLoading}
                  />
                </CustCol>

                <CustCol cols={4}>
                  <CustText
                    id="ccName"
                    label="Name on card"
                    onChange={(e) => handleFormFieldChange(e.target.id, e.target.value)}
                    onFocus={handleCcFieldFocus}
                    value={paymentInfo.ccName}
                    disabled={localState.isLoading}
                  />
                </CustCol>
                
                <CustCol cols={2}>
                  <CustText
                    id="ccExpiration"
                    label="Expires (MMYY)"
                    onChange={(e) => handleFormFieldChange(e.target.id, e.target.value)}
                    onFocus={handleCcFieldFocus}
                    value={paymentInfo.ccExpiration}
                    type="number"
                    disabled={localState.isLoading}
                  />
                </CustCol>

                <CustCol cols={2}>
                  <CustText
                    id="ccCvC"
                    label="CVC"
                    onChange={(e) => handleFormFieldChange(e.target.id, e.target.value)}
                    onFocus={handleCcFieldFocus}
                    value={paymentInfo.ccCvC}
                    type="number"
                    disabled={localState.isLoading}
                  />
                </CustCol>
              </CustRow>

              <div className="my-5 large-screen-content">
                <Cards
                  number={paymentInfo.ccNumber}
                  expiry={paymentInfo.ccExpiration}
                  cvc={paymentInfo.ccCvC}
                  name={paymentInfo.ccName}
                  focused={paymentInfo.ccFocused}
                />
              </div>
            </div>
          }

          {!paymentInfo.useVault && !!localState.choseAch && 
            <div className="mb-5">
              <CustRow>
                <CustCol cols={3}>
                  <CustText
                    id="achRouting"
                    label="Routing #"
                    onChange={(e) => {
                      handleAchVerification(e, 9);
                    }}
                    tabIndex={50}
                    value={paymentInfo.achRouting}
                    errorMessage="Routing # must be 9 digits"
                    valid={!!paymentInfo.achRouting && paymentInfo.achRouting.length === 9}
                    showValidationState={!!paymentInfo.achRouting}
                    type="number"
                    disabled={localState.isLoading}
                  />
                </CustCol>

                <CustCol cols={3}>
                  <CustText
                    id="achAccount"
                    label="ACH Account"
                    onChange={(e) => {
                      handleAchVerification(e, 17);
                    }}
                    tabIndex={60}
                    value={paymentInfo.achAccount}
                    type="number"
                    disabled={localState.isLoading}
                  />
                </CustCol>

                <CustCol cols={3}>
                  <CustDropDown
                    id="accountType"
                    selectedValue={paymentInfo.accountType}
                    label="Account Type"
                    onChange={(e) => {
                      handleFormFieldChange(e.target.id, e.target.value);
                    }}
                    tabIndex={70}
                    options={accountTypeOptions}
                    disabled={localState.isLoading}
                  />
                </CustCol>

                <CustCol cols={3}>
                  <CustDropDown
                    id="checkType"
                    selectedValue={paymentInfo.checkType}
                    label="Check Type"
                    onChange={(e) => {
                      handleFormFieldChange(e.target.id, e.target.value);
                    }}
                    tabIndex={80}
                    options={checkTypeOptions}
                    disabled={localState.isLoading}
                  />
                </CustCol>
              </CustRow>
            </div>
          }

          {!paymentInfo.useVault && (
            <div>
              <CustRow>
                <CustCol cols={12}>
                  <CustSwitch
                    id="store-to-vault"
                    label={windowWidth > 1000
                      ? "Store this information in the secure customer vault so it doesn't need to be entered again next time?"
                      : "Store this info in the secure customer vault?"
                    }
                    checked={paymentInfo.storeToVault}
                    labelPosition="left"
                    onChange={(e) => handleFormFieldChange("storeToVault", e.target.checked)}
                  />
                </CustCol>
              </CustRow>

              <div className="comp-input-label ml-10 large-screen-content" style={{lineHeight: 1.2}}>
                <label>
                  Why is this safe?
                  <IconAlert
                    id='why-is-this-safe-icon'
                    className="icon icon-alert ml-1 cursor-pointer"
                  />
                </label>
                <Popover
                target='#why-is-this-safe-icon'
                showEvent="mouseenter"
                hideEvent={{name: "mouseleave", delay: 300}}
                position="top"
                width={400}
                animation={animationConfig}
              >
                <div>
                 <p style={{fontSize: "120%", fontWeight: 500}} className="mb-3">Using the customer vault is safe because:</p>
                  <ol className="pl-7" style={{backgroundColor: "lightyellow"}}>
                    <li>We only keep the last four digits of your account number, and use a 3rd party PCI DSS Level 1 vault to ensure security. </li>
                    <li>We use an approved and certified tokenization strategy, which means that any attempt by another party to use the token will fail.</li>
                    <li>Transactions will only be processed with your consent.</li>
                    <li>You may delete this information whenever you wish.</li>
                  </ol>
                </div> 
              </Popover>
              </div>
            </div>
          )}
        </ModalBody>
        <ModalFooter>
          <p>By clicking the Submit button below, I certify that I am an authorized representative of {order.customer?.businessName} ("Company") and that I have the authority to authorize {localState.config.clientName} to Debit or Credit the account referenced above.</p>
          <p className="mt-3">Furthermore, if the payment is returned for any reason, I authorize {localState.config.clientName} to debit a rejected/returned item fee of $25, unless that exceeds the maximum amount allowed by law, in which case the maximum amount allowed by law shall be assessed.</p>

          <CustRow className="mt-10 mb-10">
            <CustCol offset={1} cols={6}>
              <CustButton
                id="submit-button"
                onClick={() => finishSubmit()}
                custType="primary"
                tabIndex={90}
                disabled={!isValidPaymentInfo || localState.isLoading}
              >
                <span className="dx-button-text">{!!isValidPaymentInfo ? `Submit payment of ${formatCurrency(localState.selectedBalanceTotal)} via ${localState.choseAch ? "ACH" : "credit card"}` : "Fill out all fields to continue"}</span>
                {localState.isLoading && <img className="spinner-button" style={{margin: "5px", paddingBottom: "5px"}} src={spinner} alt="Loading..." />}
              </CustButton>
            </CustCol>

            <CustCol cols={2}>
              <CustButton
                id="cancel-delete-button"
                onClick={handleCancelSubmit}
                type="default"
                text="Cancel"
                tabIndex={100}
                disabled={localState.isLoading}
              />
            </CustCol>
          </CustRow> 
        </ModalFooter>
      </CustModal>
    </>
  );
}

export default Pay;
